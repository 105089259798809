/*
Wenn ich auf einen Anchor klicke muss der Punkt mit der ID sichtbar werden.
Dazu muss ich ihn informieren und
er muss diese Info an die Parents in der Hierarchie nach oben weitergeben.
*/
import React, { createContext, useState } from "react";


export const ContextAnchor = createContext({   
    anchorId: "",
    setAnchorId: () => {
        // console.log("setAnchorId gesetzt: ", anchorId);
    }
});

export const ContextAnchorProvider = ({ children }) => {
    const [anchorId, setAnchorId] = useState("");
  
    return (
      <ContextAnchor.Provider value={{ anchorId, setAnchorId }}>
        {children}
      </ContextAnchor.Provider>
    );
  };