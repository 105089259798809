import React from "react";

const Counter = (props) => {
  const { counter } = props;
  return (
//<div className="-translate-x-4 sm:-translate-x-5 -translate-y-2 sm:-translate-y-5  flex-shrink-0 flex items-center justify-center h-9 sm:h-8 w-5 sm:w-8 bg-gray-500 text-white font-bold text-lg sm:text-2xl rounded-full "  >
<div className="sticky top-0 left-0 transform -translate-x-3/4 w-10 sm:w-12 h-10 sm:h-12 bg-white text-gray-500 font-bold text-lg sm:text-xl flex items-center justify-center rounded-full pl-2 shadow-md">
  {counter}
</div>




  );
};

export default Counter;
