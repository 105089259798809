
import React, { useState } from "react";
import { useLayoutEffect } from "react";
import mermaid from "mermaid";
import { getMermaids, useGetMermaid, useMermaidQuery } from "../hooks/useMermaidQuery";

const SubPointMermaidGeneration = (props) => {
  const { src, folder, language } = props;

  const mermaidConfig = {
    theme: "overtheme",
    themeCSS: `
        .nodeLabel { color: #343a40;}
        .edgeLabel {font-size: 0.9em; color: #343a40; background-color: #e9ecef; border-radius: 5px; padding: 3px; }
        .node rect { font-family: quicksand, sans-serif;
            font-weight: bold; font-size: 1.1em;
            fill: #b2f2bb;
            stroke-width: 6px; stroke: #343a40; }
        .node polygon { 
          fill: #e9ecef; // Helle Füllfarbe für bessere Lesbarkeit von Texten
          color: #343a40; // Dunkle Schriftfarbe
          stroke-width: 3px; stroke: #5c636a; // Dunkelgrauer Rahmen
        stroke-width: 6px; stroke: #343a40; }

        .edgePaths path { stroke: #000;  stroke-width: 4px; }

        .label { font-family: quicksand, sans-serif;
            font-weight: bold; font-size: 1.5em;
            fill: #eee; font-color: #00f;
             }
        .cluster-label { // CSS-Klasse für Subgraph-Beschriftungen
            font-family: 'Quicksand', sans-serif;
            font-weight: bold; font-size: 1.7em; // Größere Schriftgröße für Subgraph-Beschriftungen
            fill: #343a40; // Dunkle Schriftfarbe für bessere Lesbarkeit auf hellem Hintergrund
        }
    `,
  };
// Achtung: Hier werden die Classes beschrieben:
// useGetMermaid

  useLayoutEffect(() => {
    mermaid.initialize(mermaidConfig);
    mermaid.contentLoaded();
  }, []);


  return <div class="mermaid">{useGetMermaid(src, folder, language)}</div>;
};

export default SubPointMermaidGeneration;
