import React, { useState, useEffect } from "react";

import SubPointMermaidGeneration from "./or_sub_point_mermaidgeneration";
import { useGetMermaid } from "../hooks/useMermaidQuery";


const SubPointMermaid = (props) => {
  const { src, folder, alt, showSelf, id, language } = props;
  const cssUrl = src ? "/images/" + folder + "/" + src + ".css" : "";
  
  //CSS mit SVG Files laden: Es muss unter static/images/[spiel]/[mermaid_name].css eine Datei liegen, die die Styles enthält.
  //svg beim export optimiert ohne Einrückungen, ohne boundingbox
  useEffect(() => {
    const link = document.createElement('link');
    link.href = cssUrl;
    link.type = 'text/css';
    link.rel = 'stylesheet';
    document.head.appendChild(link);
    console.log("CSS loaded: " + cssUrl);
    return () => {
      document.head.removeChild(link);
    };
  }, [cssUrl]);
  // alt: const fixMermaidUrl = src ? "/images/" + folder + "/" + src + "_" + language + ".md" : "";

  const [isModalOpen, setIsModalOpen] = useState(false);

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };




  return (
    <div
      id={id}
      className="cursor-pointer"
      onClick={toggleModal}
      onKeyDown={toggleModal}
      role="presentation"
    >
        <SubPointMermaidGeneration language={language} folder={folder} src={src} />

      {/* <div>{mermaidCode}</div> */}

    </div>
  );
};

export default SubPointMermaid;
