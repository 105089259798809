import React, { useState } from "react";
import { useStaticQuery, graphql } from "gatsby";
import Flag from "./langChooser_flag";

const OrLangChooser = ({ activeLanguage, slug }) => {
  const data = useStaticQuery(graphql`
    query {
      allMarkdownRemark {
        nodes {
          frontmatter {
            language
            folder
            listgame
            create_page
          }
        }
      }
    }
  `);
  const languages = Array.from(
    new Set(
      data.allMarkdownRemark.nodes
        // Optional: Filter nodes by slug if slug is not undefined
        .filter((node) => (slug ? node.frontmatter.folder === slug : true))
        //Filter if node.frontmatter.listgame === true
        //.filter(node => node.frontmatter.listgame === true)
        .filter((node) => node.frontmatter.create_page === true)
        .map((node) => node.frontmatter.language)
    )
  ).filter((lang) => lang !== activeLanguage);

  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const togglePopup = () => setIsPopupOpen(!isPopupOpen);
  const closePopup = () => setIsPopupOpen(false);
  //const openPopup = () => setIsPopupOpen(true);

  const linkTo = slug !== undefined ? `/${slug}/rules` : "";

  return (
    <div className="relative w-12 sm:w-12 flex justify-center">
      {languages.length === 0 && (
        <div className="relative ">
          <Flag lang={activeLanguage} smallSize={true} />
          <div className="absolute inset-0  opacity-40 rounded-md "></div>
        </div>
      )}
      {languages.length !== 0 && (
        <button onClick={togglePopup} onKeyDown={togglePopup}>
          <Flag lang={activeLanguage} smallSize={true} />
        </button>
      )}

{isPopupOpen && (
  <div
    className="fixed inset-0 bg-white bg-opacity-20 flex items-center justify-center z-10"
    onClick={closePopup}
    onKeyDown={closePopup}
    role="button"
    tabIndex={0}
    aria-label="Close language selector"
  >
    <div className="bg-yellow-100 shadow-xl rounded-lg p-4 z-10">
      <div className="grid grid-cols-3 gap-4 justify-center">
        {languages.length !== 0 &&
          languages.map((lang) => (
            <a
              href={`/${lang}${linkTo}`}
              key={lang}
              className="flex justify-center text-gray-800 hover:bg-gray-100"
            >
              <Flag lang={lang} size="3" />
            </a>
          ))}
      </div>
    </div>
  </div>
)}
    </div>
  );
};

export default OrLangChooser;
