import { StaticImage } from "gatsby-plugin-image";
import React from "react";

const IconExpandReduce = (props) => {
  const {
    expand = false,
    reduce = false,
    vid = false,
    image = false,
    mermaid = false,
    link = false,
    miniVid = false,
    miniMermaid = false,
    pic = false,
    miniPic = false
  } = props;

  return (
    <div className="flex flex-col md:flex-row items-center">
      {link && (
        <StaticImage
          src="../images/link.svg"
          alt="Link"
          className="ml-8 w-6 md:w-8 "
          placeholder="blurred"
        />
      )}
      {vid && (
        <StaticImage
          src="../images/vid.svg"
          alt="Animatioin inside"
          className="m-1 w-6 md:w-10 "
          placeholder="blurred"
        />
      )}
      {pic && (
        <StaticImage
          src="../images/pic.svg"
          alt="Picture inside"
          className="m-1 w-6 md:w-8 "
          placeholder="blurred"
        />
      )}  
      {mermaid && (
        <StaticImage
          src="../images/mermaid.svg"
          alt="Mermaid"
          className="m-1 w-6 md:w-8 "
          placeholder="blurred"
        />
      )}
      {miniVid && (
        <StaticImage
          src="../images/vid.svg"
          alt="Animatioin inside"
          className="m-1 w-2 md:w-4 "
          placeholder="blurred"
        />
      )}
      {miniPic && (
        <StaticImage
          src="../images/pic.svg"
          alt="Picture"
          className="m-1 w-2 md:w-4 "
          placeholder="blurred"
        />
      )}
      {miniMermaid && (
        <StaticImage
          src="../images/mermaid.svg"
          alt="Mermaid"
          className="m-1 w-2 md:w-4 "
          placeholder="blurred"
        />
      )}
      {image && <h1>Image</h1>}
      {expand && (
        <StaticImage
          src="../images/expandl.svg"
          alt="Expand"
          className="m-1 w-6 md:w-8 "
          placeholder="blurred"
        />
      )}
      {reduce && (
        <StaticImage
          src="../images/reducel.svg"
          alt="Collapse"
          className="m-1 w-6 md:w-8 "
          placeholder="blurred"
        />
      )}
    </div>
  );
};

export default IconExpandReduce;
