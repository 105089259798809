import React, { useState, useContext } from "react";
import ButtonImage from "./buttonImage";
import OrNavigationSearch from "./or_navigation_search";
import { useCollapseContext } from "../store/contextCollapse";
import { navigate } from "gatsby";
import { ContextAnchor } from "../store/contextAnchor";
import HeadIcon from "./headIcon1";
import OrLangChooser from "./or_langChooser";

const OrNavigation = (props) => {
  const { rulesObj, folder, language } = props;
  const { triggerCollapseAll } = useCollapseContext();
  const { setAnchorId } = useContext(ContextAnchor);

  const backUrl = `/${language}/${folder}`;
  const [searchQuery, setQuery] = useState(""); // Zustand für die Suchanfrage
  const [searchResults, setSearchResults] = useState([]); // muss ich hier speichern, sonst gehts verloren, wenn suchfeld zugeklappt wird

  const [isSearchBarOpen, setIsSearchBarOpen] = useState(false);
  const [isBuregerMenuOpen, setIsBuregerMenuOpen] = useState(false);

  const toggleSearchBar = () => setIsSearchBarOpen(!isSearchBarOpen);
  const toggleBurgerMenu = () => {
    setIsBuregerMenuOpen(!isBuregerMenuOpen);
    setIsSearchBarOpen(false);
  };

  const handleAnchorClick = (anchorId) => {
    setIsSearchBarOpen(false);
    setTimeout(() => navigate(`#${anchorId}`), 50);
    setAnchorId(anchorId);
    // navigate(`#${anchorId}`);
  };
  // const closeSearchBar = () => setIsSearchBarOpen(false);
  // const openSearchBar = () => setIsSearchBarOpen(true);

  // aus rulesObj.main_categories alle type:point iterieren und name und id/anchor speichern key= anchor || name
  const createSearchArray = (data, searchArray = []) => {
    data.forEach((item) => {
      if (item.type === "point") {
        searchArray.push({
          id: item.anchor || item.id,
          name: item.name,
        });
      }
      if (item.children && item.children.length > 0) {
        createSearchArray(item.children, searchArray);
      }
    });
    return searchArray;
  };

  return (
    <nav className="sticky top-0 flex  bg-gradient-to-b from-yellow-200 to-yellow-100 rounded-b-lg z-50 justify-between shadow-md border-b border-gray-400 ">
      
      <a href={`/${language}/`} className="hidden xs:flex sm:flex justify-start items-center p-0 gap-0 md:gap-2 no-underline">
  <HeadIcon icon={"logo"} />
  <div className="hidden md:flex items-center text-2xl sm:text-3xl font-bold my-0">
    <span className="text-blue-300">over</span>
    <span className="text-blue-600">Rules</span>
  </div>
</a>

      
      <div className=" flex justify-end place-items-start  items-center h-full">
      {/* <div className="flex-shrink min-w-0 max-w-[75px] md:max-w-[85px] cursor-pointer p-1 h-full ">
          <a href={backUrl} className="h-full" aria-current="page">
            <CategoryImage category="0_home" />
          </a>
        </div> */}
      </div>

      <ul className="flex overflow-auto">
        {rulesObj &&
          rulesObj.main_categories.map((category) => (
            <li className="flex-shrink min-w-0 max-w-[80px] md:max-w-[100px] h-full">
              <a href={`#${category.id}`} className="h-full">
                <ButtonImage category={category.cat} />
              </a>
            </li>
          ))}
      </ul>

      <div className="flex justify-end place-items-start  items-center h-full">
        <div
          className="flex-shrink justify-end min-w-0 max-w-[75px] md:max-w-[85px] cursor-pointer p-1 h-full "
          onClick={toggleBurgerMenu}
        >
          <ButtonImage category="W_burger" />
        </div>
      </div>
      {isBuregerMenuOpen && <div className="absolute  top-14 md:top-16 right-0 flex flex-col items-end bg-yellow-100 rou rounded-b-3xl">
      <div 
            className="flex-shrink justify-end min-w-0 max-w-[75px] md:max-w-[85px] cursor-pointer p-1 h-full "
            onClick={toggleSearchBar}
          >
            <ButtonImage category="Z_zoom" />
          </div>
        
        <div className="flex justify-end place-items-start  items-center h-full">
          <div
            className="flex-shrink justify-end min-w-0 max-w-[75px] md:max-w-[85px] cursor-pointer p-1 h-full "
            onClick={triggerCollapseAll}
          >
            <ButtonImage category="Y_collapse" />
          </div>
        </div>
        <div className="flex justify-end place-items-start  items-center h-full">

        </div>
        <div className="flex justify-end place-items-start  items-center h-full">
          <div className="flex-shrink justify-end cursor-pointer p-1 h-full ">
            <OrLangChooser activeLanguage={language} slug={folder}></OrLangChooser>
          </div>
        </div>
        

        <div className="flex-shrink justify-end min-w-0 max-w-[75px] md:max-w-[85px] cursor-pointer p-1 h-full ">
          <a href={backUrl} className="h-full" aria-current="page">
            <ButtonImage category="X_exit" />
          </a>
        </div>
      </div>}

      {isSearchBarOpen && <OrNavigationSearch rulesObj={rulesObj}  searchQuery={searchQuery} setQuery={setQuery}  searchResults={searchResults} setSearchResults={setSearchResults} onAnchorClick={handleAnchorClick}/>}

    </nav>
  );
};

export default OrNavigation;
