import React from "react";
/* hier wird die Suche in der Navigation implementiert 

*/

// Helper functions
const removeAsterisks = (text) => text.replace(/\*/g, "");

const buildSearchArray = (data, searchArray = []) => {
  data.forEach((item) => {
    if (item.type === "point") {
      searchArray.push({
        id: item.anchor || item.id,
        name: removeAsterisks(item.name),
      });
    }
    if (item.children?.length > 0) {
      buildSearchArray(item.children, searchArray);
    }
  });
  return searchArray;
};

const filterSearchResults = (query, dataArray) =>
  dataArray.filter((item) =>
    item.name.toLowerCase().includes(query.toLowerCase())
  );
////////////////////////////////////////////////////////////////////////
const NavigationSearch = ({
  rulesObj,
  searchQuery,
  setQuery,
  searchResults,
  setSearchResults,
  onAnchorClick,
}) => {

  const searchArray = buildSearchArray(rulesObj.main_categories);

  const handleSearch = (event) => {
    const newQuery = event.target.value;
    setQuery(newQuery);
    setSearchResults(
      newQuery ? filterSearchResults(newQuery, searchArray).slice(0, 15) : []
    );
  };


  const renderSearchInput = () => (
    <div><input
      ref={(input) => input && input.focus()}
      className="flex h-10 w-full border border-gray-600 rounded-md pl-3 shadow-xl"
      type="text"
      value={searchQuery}
      onChange={handleSearch}
    />
              {searchQuery && ( // Klick auf X löscht den Text
          <button
            onClick={() => setQuery("")}
            className="absolute -translate-y-6 sm:-translate-y-7 right-0 pr-3 flex items-center text-sm leading-5"
          >
            <svg
              fill="none"
              stroke="currentColor"
              className="h-3 w-3 sm:h-5 sm:w-5 text-gray-300"
              version="1.1"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="m3.5676 19.623 16.865-15.246m-16.865 0 16.865 15.246"
                fill="none"
                stroke-linecap="round"
                
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="6"
              />
            </svg>
          </button>
        )}
    </ div> 
  );

  const renderSearchResults = () => (
    
    <ul className=" translate-y-1  bg-yellow-50 p-1 border border-gray-800 rounded-md drop-shadow-2xl shadow-xl ">
      {searchResults.map((item) => (
        <li
          className="border border-gray-300 bg-white m-1 px-1 rounded-md"
          key={item.id}
          onClick={() => onAnchorClick(item.id)}
          onKeyDown={() => onAnchorClick(item.id)}
        >
          <div className="truncate cursor-pointer">{item.name}</div>
        </li>
      ))}
    </ul>
    
  );

  return (
    <div className="absolute w-screen md:w-2/3 top-16 md:top-20 right-20 sm:right-16 flex flex-col items-end">
      <div className="relative pl-16 w-full max-w-md left-6 sm:left-0">
        {renderSearchInput()}
        
        {/* {searchQuery ? renderSearchResults() : <LoadingDots />} */}
        {searchQuery ? renderSearchResults() :""}

      </div>
    </div>
  );
};
////////////////////////////////////////////////////////////////////////
// const LoadingDots = ({ startLeft = 97, gap = 10 }) => {
//   return (
//     <div>
//       <div
//         className={`absolute animate-pulse inset-y-7 left-[${startLeft}px] h-1 w-1 rounded-md bg-gray-500`}
//       />
//       <div
//         className={`absolute animate-pulse inset-y-7 left-[${
//           startLeft + gap
//         }px] h-1 w-1 rounded-md bg-gray-500`}
//       />
//       <div
//         className={`absolute animate-pulse inset-y-7 left-[${
//           startLeft + gap * 2
//         }px] h-1 w-1 rounded-md bg-gray-500`}
//       />
//     </div>
//   );
// };

export default NavigationSearch;