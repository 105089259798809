import React from 'react';



const MarkdownText = ({ text, showSelf=true }) => {


    const splitText = text? text.split("**") : [];
    const newText = splitText.map((t, i) => {
      if (i % 2 !== 0) {
        return <span className='font-bold' >{t}</span>;
      } else {
        return t;
      }
    });
    return (
          <div className={`transition-all duration-300 ease-in-out  ${showSelf ? "max-h-[1000px]": "max-h-0 hidden"} `}
          >{newText}</div>
    );


};

export default MarkdownText;