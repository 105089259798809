import React, { useContext, useEffect, useState } from "react";
import { ContextAnchor } from "../store/contextAnchor";

import SubPointImage from "./or_sub_point_image";
import SubPointMermaid from "./or_sub_point_mermaid";
import SubPointNoChild from "./or_sub_point_no_child";
import SubPointWithChildren from "./or_sub_point_with_children";

const SubPoint = ({ key,  subCategory,  showSelf,folder, onhandleOpenAnchor, language }) => {
  const [showSubs, setShowSubs] = useState(true);

  // useEffect(() => {
  //    setShowSubs(true); // Aktualisiert den lokalen Zustand, wenn sich 'show' ändert
  // }, [showSubs]);

  const { anchorId } = useContext(ContextAnchor);

  const { name, children, image, type, linkto, anchor, id, pre_svg, post_svg } = subCategory;

  const isMermaid = type === "mermaid" ? true : false;
  const isImage = type === "image" || type === "stillimage" ? true : false;
  
  const hasChildren = children && children.length > 0 ? true : false;

  const itemID = anchor || id;

  useEffect(() => {
    if ( anchorId === itemID) {
      // console.log("Sub Point useEffect ausgelöst: ", anchorId, " hier im ", itemID );
      handleOpenAnchor(itemID);
    }
  }, [anchorId]);



  // const handleEffectOpenAnchor = (anch) => {
  //   if (anchor && anch === itemID) {
  //     console.log("Sub Point useEffect ausgelöst: ", anch, " hier im ", itemID );
  //     handleOpenAnchor(anch);
  //   }
  // };
  const handleOpenAnchor = (anchor) => {
    setShowSubs(true);
    onhandleOpenAnchor(anchor); // zum Parent weitergeben
  };

  // 4 Möglichkeiten:
  // 1. Bild, keine Kinder
  // 2. Kein Bild, keine Kinder
  // 3. Kinder, klickbar
  // 4. Mermaid, klickbar
  return (
    <div
      className={`transition-all duration-300 ease-in  bg-white rounded-l-lg shadow-lg ${
        image ? "rounded-r-lg mx-4" : ""
      }  border-t-0 border-l-2 border-gray-400  ${
        showSelf
          ? "m-0 mb-1 p-2 max-h-[1000] " //achtung hier showSubs ist ein Fehler
          : "m-0 p-0 max-h-0 overflow-hidden"
      }`}
    >
      {isImage && (
        <SubPointImage
          src={image}
          folder={folder}
          alt={name}
          showSelf={showSubs}
          id={itemID}
        />
      )}
      {isMermaid && (
        <SubPointMermaid
          src={image}
          folder={folder}
          alt={name}
          showSelf={showSubs}
          id={itemID}
          language={language}
        />
      )}
      {name && !hasChildren && (
        <SubPointNoChild
          text={name}
          showSelf={showSubs}
          linkto={linkto}
          id={itemID}
          pre_svg={pre_svg}
          post_svg={post_svg}
          folder={folder}
        />
      )}
      {hasChildren && (
        <SubPointWithChildren
          key={key}
          text={name}
          folder={folder}
          language={language}
          subCategory={subCategory}
          showSelf={showSubs}
          id={itemID}
          pre_svg={pre_svg}
          post_svg={post_svg}
          onhandleOpenAnchor={handleOpenAnchor}
        />
      )}
    </div>
  );
};

export default SubPoint;
