import React, { createContext, useContext, useState } from 'react';

const CollapseContext = createContext();

export const useCollapseContext = () => useContext(CollapseContext);

export const CollapseProvider = ({ children }) => {
  const [collapseAllTrigger, setCollapseAllTrigger] = useState(false);

  const triggerCollapseAll = () => {
    setCollapseAllTrigger(true);
    // Setzen Sie es kurz darauf zurück auf false, um erneute Trigger zu ermöglichen
    setTimeout(() => setCollapseAllTrigger(false), 0);
  };

  return (
    <CollapseContext.Provider value={{ collapseAllTrigger, triggerCollapseAll }}>
      {children}
    </CollapseContext.Provider>
  );
};
