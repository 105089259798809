import React, { useContext } from "react";
import {  navigate } from "gatsby";
import { ContextAnchor } from "../store/contextAnchor";
import MarkdownText from "./markdownText";
import PrePostSvg from "./or_point_pre_svg";
import IconExpandReduce from "./or_point_icons_expand_reduce";

const SubPointNoChild = ({
  text,
  showSelf,
  linkto,
  id,
  pre_svg,
  post_svg,
  folder,
}) => {
  const { setAnchorId } = useContext(ContextAnchor);

  const handleAnchorClick = () => {
    setAnchorId(linkto);
    // console.log("SubPointNoChild handleAnchorClick: ", linkto);
    // console.log("navigiere jetzt");

    window.history.pushState({}, '', `#${id}`);
    navigate(`#${linkto}`);
    //hier sollte die aktuelle id gespeichert werden, in ein Array im localStorage (backId)
    //beim Pfeil Back sollte dann die letzte id aus dem Array genommen werden
    //und gespeichert werden in (nextId)
    //nur wenn backID.length > 0 soll ein backPfeil angezeigt werden
    //wenn ein anderer anchor geklickt wird, soll das nextId Array geleert werden
    //die Arrayverwaltung muss oben in overruls.js erfolgen
  };

  // const addBackIdtoLocalStorage = (lastId, aktId) => {
  //   const backIdArray = JSON.parse(localStorage.getItem("backId")) || [];
  //   // wenn das letzte element gleich ist, wie lastId, soll es nicht angehängt werden
  //   if (backIdArray.length > 0) {
  //     if (backIdArray[backIdArray.length - 1] === lastId) {
  //       return;
  //     }
  //   }
  //   backIdArray.push(lastId);
  //   localStorage.setItem("backId", JSON.stringify(backIdArray));
  //   localStorage.setItem("thisId", aktId);
  // };

  return (
    <div
      id={id}
      className={`flex items-center transition-all duration-500 ease-in-out  ${
        showSelf ? "max-h-[1000px]" : "max-h-0"
      } `}
    >
      {linkto && (
        <div  onClick={handleAnchorClick} className="flex items-center cursor-pointer">
        {/* <a href={`#${id}`} onClick={handleAnchorClick} className="flex items-center"> */}
          {pre_svg && <PrePostSvg pre_svg={pre_svg} folder={folder} />}
          <MarkdownText text={text} showSelf={showSelf} className="flex" />
          {post_svg && <PrePostSvg post_svg={post_svg} folder={folder} />}
          <IconExpandReduce link={true} />
        </div>
      )}
      {!linkto && (
        <>
          {pre_svg && <PrePostSvg pre_svg={pre_svg} folder={folder} />}
          <MarkdownText text={text} showSelf={showSelf} className="flex" />
          {post_svg && <PrePostSvg post_svg={post_svg} folder={folder} />}
        </>
      )}
    </div>
  );
};

export default SubPointNoChild;
