import React from 'react';
import MainPoint from './or_main_point';
import CategoryImage from './buttonImage';

const Category = (props) => {
  const { mainCategory, folder, language } = props;
  const { id, name, children, cat } = mainCategory;


//console.log("or_Category: ", cat)
  return (
    <h2 className="or-main-category mt-8 md:mt-14">
      
      <div id={id} className="flex font-bold items-center  text-2xl sm:text-4xl mb-4 md:mb-10  ">
      <span className="w-12 sm:w-16">
      <CategoryImage category={cat} /></span>
      <div className='pl-1 sm:pl-6 text-gray-800'>{name}</div></div>

        <div className="or-main-category__sub-categories">
          {children.map((children, index) => (
            <MainPoint  folder={folder} subCategory={children} counter={index+1} cat={cat} language={language} />
            
          ))}
        </div>

    </h2>
  );
}

export default Category;