import { useStaticQuery, graphql } from "gatsby";



//test-de.json wird benötigt, um diese Struktur zu erlauben
//sonst gibts Fehler, weil Felder nicht vorhanden sind

export const useOverrulesQuery = () => {
  const data = useStaticQuery(graphql`
  query {
    allJson {
      nodes {
        main_categories {
          anchor
          cat
          id
          name
          post_svg
          pre_svg
          type
          children {
            alt
            anchor
            id
            image
            linkto
            name
            post_svg
            pre_svg
            type
            children {
              alt
              anchor
              id
              image
              linkto
              name
              post_svg
              pre_svg
              type
              children {
                alt
                anchor
                id
                image
                linkto
                name
                post_svg
                pre_svg
                type
                children {
                  alt
                  anchor
                  id
                  image
                  linkto
                  name
                  post_svg
                  pre_svg
                  type
                  children {
                    alt
                    anchor
                    id
                    image
                    linkto
                    name
                    post_svg
                    pre_svg
                    type
                    children {
                      alt
                      anchor
                      id
                      image
                      linkto
                      name
                      post_svg
                      pre_svg
                      type
                      children {
                        alt
                        anchor
                        id
                        image
                        linkto
                        name
                        post_svg
                        pre_svg
                        type
                        children {
                          alt
                          anchor
                          id
                          image
                          linkto
                          name
                          post_svg
                          pre_svg
                          type
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
        language
        name
        slug
      }
    }
  }
`)

  return data.allJson.nodes;
}


// export const useOverrulesQuery = () => {
//     const data = useStaticQuery(graphql`
//     query {
//       allJson {
//         nodes {
//           main_categories {
//             id
//             name
//             type
//             cat
//             children {
//               id
//               name
//               type
              
//               children {
//                 id
//                 image
//                 name
//                 type
//                 image
//                 children {
//                   children {
//                     id
//                     image
//                     type
//                   }
//                   id
//                   image
//                   name
//                   type
//                 }
//               }
//             }
//           }
//           language
//           slug
//         }
//       }
//     }
//   `)

//     return data.allJson.nodes;
// }
