
import React from "react";

const PrePostSvg = ({ pre_svg = false, post_svg = false, folder }) => {
  return (
    <div>
      {pre_svg && (
        <div className="flex w-8 -translate-x-1">
          <img src={"/images/" + folder + "/" + pre_svg} alt={pre_svg} />
        </div>
      )}
      {post_svg && (

        <div className="flex w-8 ml-4">
          <img src={"/images/" + folder + "/" + post_svg} alt={post_svg} />
        </div>
      )}
    </div>
  );
};

export default PrePostSvg;
