import React from "react";
import { useStaticQuery, graphql } from "gatsby";
/**
 * Achtung! Diese .md Files liegen hier:
 *
 * *****   /content/games/[spiel]/images/*_[language].md  *****
 *
 */

const useMermaidQuery = () => {
  const data = useStaticQuery(graphql`
    query {
      allMarkdownRemark(filter: { frontmatter: { type: { eq: "mermaid" } } }) {
        nodes {
          frontmatter {
            language
            folder
            name
            type
          }
          rawMarkdownBody
        }
      }
    }
  `);

  return data.allMarkdownRemark.nodes;
};

export const useGetMermaid = (src, folder, language) => {
  const mermaidData = useMermaidQuery();
  if (mermaidData) {
    const mermaid = mermaidData.filter(
      (mermaid) =>
        mermaid.frontmatter.language === language &&
        mermaid.frontmatter.folder === folder &&
        mermaid.frontmatter.name === src
    );
    if (mermaid.length > 0) {

      const cleanedMermaid = mermaid[0].rawMarkdownBody + `
          %% Defining the styles  
          classDef Green fill:#b2f2bb,stroke:#333,stroke-width:6px
          classDef Blue fill:#a5d8ff,stroke:#333,stroke-width:6px
          classDef Yellow fill:#ffec99,stroke:#333,stroke-width:6px
          classDef Orange fill:#fc9,stroke:#333,stroke-width:6px
          classDef Red fill:#FF9999,stroke:#333,stroke-width:6px
          classDef Lila fill:#d0bfff,stroke:#333,stroke-width:6px
          classDef Turkis fill:#99e9f2,stroke:#333,stroke-width:6px
          classDef Subs fill:#00000000, stroke-width:1px, color:black;

          classDef SvgBox fill:#0000,stroke:#0002,stroke-width:1px;
          classDef SvgNode fill:#0000,stroke:#0001,stroke-width:0px;
          classDef SvgNodeBlue fill:#a5d8ff99,stroke:#a5d8ff,stroke-width:2px;
          
          linkStyle default stroke: #343a40, color:#ffaa00, arrow: #ffaa00,  stroke-width:4px;
          
          `
      return cleanedMermaid;
    } else {
      return "Mermaid not found:" + src + " in: " + folder + " lng: " + language;
    }
  } else {
    return "No Mermaids found";
  }
};
