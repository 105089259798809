import React, { useState,  useEffect } from "react";
import MarkdownText from "./markdownText";
import IconExpandReduce from "./or_point_icons_expand_reduce";
import SubPoint from "./or_sub_point";
import PrePostSvg from "./or_point_pre_svg";
import { useCollapseContext } from '../store/contextCollapse';

const SubPointWithChildren = ({key, text, subCategory, folder, pre_svg, post_svg, showSelf, id, onhandleOpenAnchor, language}) => {
  const { children } = subCategory;

  const [showSubCategories, setShowSubCategories] = useState(false);
  const { collapseAllTrigger } = useCollapseContext();

  const toggleSubCategories = () => {
    setShowSubCategories(!showSubCategories);
  };

  const showExpand = children && !showSubCategories;
  const showReduce = children && showSubCategories;
  //const showVideo = children && children[0].type === "image";
  const showVideo = children && children.length > 0 && children.some(child => child.type === "image");
  const showMermaid = children && children.length > 0 && children.some(child => child.type === "mermaid");
  const showStillImage = children && children.length > 0 && children.some(child => child.type === "stillimage");
  const showMiniVideo = children && children.length > 0 && children.some(child => child.children && child.children.some(grandChild => grandChild.type === "image"));
  const showMiniMermaid = children && children.length > 0 && children.some(child => child.children && child.children.some(grandChild => grandChild.type === "mermaid"));
  const showMiniStillImage = children && children.length > 0 && children.some(child => child.children && child.children.some(grandChild => grandChild.type === "stillimage"));



  const handleOpenAnchor = (anchor) => {
    console.log("Sub Point w Children handleOpenAnchor: ", anchor);
    setShowSubCategories(true);
    onhandleOpenAnchor(anchor);
  };

  useEffect(() => {
    if (collapseAllTrigger) {
      setShowSubCategories(false);
    }
  }, [collapseAllTrigger]);
  
  return (
    <div>
      <div id={id} key={key}
        onClick={toggleSubCategories}
        onKeyDown={toggleSubCategories}
        role="presentation"
        className={`flex justify-between cursor-pointer transition-all duration-1000 ease-in-out  ${
          showSelf ? "max-h-[1000px]" : "max-h-0"
        } `}
      >
        <div className="flex">
        {pre_svg && <PrePostSvg pre_svg={pre_svg} folder={folder} />}
        <MarkdownText text={text} showSelf={showSelf} />
        {post_svg && <PrePostSvg post_svg={post_svg} folder={folder} />}
        </div>
        {/* Icons am rechten Rand */}
        <IconExpandReduce
          expand={showExpand}
          reduce={showReduce}
          vid={showVideo}
          pic={showStillImage}
          miniPic={showMiniStillImage}
          mermaid={showMermaid}
          miniVid={showMiniVideo}
          miniMermaid={showMiniMermaid}
        />
      </div>

      <div className={`or-main-category__sub-categories`}>
        {children.map((children, index) => (
          <SubPoint
            language={language}
            key={index}
            folder={folder}
            subCategory={children}
            showSelf={showSubCategories}
            onhandleOpenAnchor={handleOpenAnchor}
          />
        ))}
      </div>
    </div>
  );
};

export default SubPointWithChildren;
