import React, { useState } from "react";
import AnimatedImage from "./animatedImage";

const SubPointImage = (props) => {
  const { src, folder, alt, showSelf, id } = props;
  const fixImageUrl = src ? "/images/" + folder + "/" + src : "";
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isVideo, setIsVideo] = useState(false);

  const toggleModal = () => {
    if (!isVideo) {
      setIsModalOpen(!isModalOpen);
    }
  };

  return (
    <div
      id={id}
      className="cursor-pointer"
      // onClick={toggleModal}
      // onKeyDown={toggleModal}
      role="presentation"
    >
      <AnimatedImage src={fixImageUrl} alt={alt} showSelf={showSelf} onMediaTypeChange={setIsVideo} />

      {/* Modal PopUp und Close */}
      <div
        className={`fixed inset-0 bg-gray-200 bg-opacity-75 flex items-center justify-center z-50 cursor-pointer transition-transform duration-300 ${
          isModalOpen ? "scale-100" : "scale-0"
        }`}
        // onClick={toggleModal}
        // onKeyDown={toggleModal}
        role="presentation"
      >
        <div
          className="bg-white rounded-lg shadow-lg p-0 m-1 cursor-pointer transition-transform duration-300"
          // onClick={(event) => event.stopPropagation()}
          // onKeyDown={(event) => event.stopPropagation()}
          role="presentation"
        >
          <AnimatedImage src={fixImageUrl} alt={alt} showSelf={true} onMediaTypeChange={setIsVideo} />
        </div>
      </div>
    </div>
  );
};

export default SubPointImage;
