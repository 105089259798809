import React from "react";
import { ContextAnchorProvider} from "../store/contextAnchor";
import { CollapseProvider } from '../store/contextCollapse';

import { useOverrulesQuery } from "../hooks/useOverrulesQuery";
import Category from "../components/or_category";
import OrNavigation from "../components/or_navigation";
import { useUiTranslationQuery } from "../hooks/useUiTranslationQuery";


const OverrulesPage = ({ pageContext }) => {
  const { language, folder, name } = pageContext;
  const allOverrules = useOverrulesQuery();

  const rulesObj = allOverrules.find(
    (item) => item.language === language && item.slug === folder
  );

  const translation = useUiTranslationQuery();
  const translationText = translation.find(
    (translation) => translation.frontmatter.language === language
  ).frontmatter

  return (
    <ContextAnchorProvider><CollapseProvider>
    <div className="bg-gray-100 font-body md:mx-auto max-w-6xl">

      <OrNavigation rulesObj={rulesObj} folder={folder} language={language} />

      <main className="mt-0 mx-1 overflow-auto h-[calc(110vh-200px)] bg-white shadow-lg rounded-lg pr-0 pl-2 sm:pl-4 scroll-smooth">

  <div className="animate-disappear">
  <div className="h-14 flex items-center justify-end mr-1 mt-3 text-gray-800">
  <span className="m-0">{`${translationText.choose_language}`}</span>
  <svg className="ml-2 w-10 h-10 mr-8 inline-block" fill="black" viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg">
  <path d="m36.548 13.63s-3.198 11.466-13.438 18.358c-10.24 6.8915-19.934 5.1968-22.302 6.5263-2.3679 1.3295 0.40525 3.7513 9.1475 3.0532 8.7422-0.69804 11.529-2.7768 17.607-6.679 6.0775-3.9022 13.609-15.941 12.831-16.373 0 0-1.4446 7.8871 0.48571 10.801 0.98023 1.4796 3.885 1.1294 4.2904-1.2976 1.5196-9.0981 1.4197-13.534 1.3762-22.785-0.02184-4.6359-2.3346-5.0696-4.6952-5.2287-2.2939-0.15457-3.4993 3.3056-9.5522 9.7704-6.0529 6.4647-6.3713 4.5845-8.4189 7.7094-1.3198 2.0142 0.64962 3.9774 2.6714 3.2059 4.9086-1.873 9.9975-7.0606 9.9975-7.0606z" stop-color="#000000"/></svg>
  </div>
</div>

        <h1 className="text-6xl md:text-8xl text-center mb-0 font-extrabold text-blue-500">
          {name}
        </h1>
        
        <div>
        
          {rulesObj?.main_categories.map((category, index) => (
            <Category key={index} folder={folder} mainCategory={category} language={language} />
          ))}
          
        </div>
        {/* Platzhalter für Footer und Banner. Response,Bewertung, Oder Cummunity Link, oder weitere Links. Außerdem Abstand zum unteren Rand */}
        <div className="h-20 mt-20"></div>

      </main>
    </div></CollapseProvider>
    </ContextAnchorProvider>
  );
};

export default OverrulesPage;

export const Head = ({ pageContext }) => {
  // die Daten in pageContext kommen auf gatsby-node-overrulepage.js aus den .md Files im /content/games/[spielname]/
  const { name, language } = pageContext;
  const translation = useUiTranslationQuery();

  const translationText = translation.find(
    (translation) => translation.frontmatter.language === language
  ).frontmatter;


  return (
    <>
      <html lang={language} ><meta name="description" content={`${translationText.meta_rules} ${name}${translationText.meta_rules_2} ${name} ${translationText.meta_rules_3}`}></meta></html>
      <title>{`${name} - ${translationText.title_rules}`}</title>
    </>
  )
}