import React, {useState, useContext, useEffect } from "react";
import { ContextAnchor } from "../store/contextAnchor";
import { useCollapseContext } from '../store/contextCollapse';
import {  navigate } from "gatsby";
import Counter from "./counter";
import SubPoint from "./or_sub_point";
import MarkdownText from "./markdownText";
import IconExpandReduce from "./or_point_icons_expand_reduce";
import PrePostSvg from "./or_point_pre_svg";


const MainPoint = (props) => {
  const { subCategory, folder, counter, cat, language } = props;
  const { name, children, anchor, linkto, id, pre_svg, post_svg } = subCategory;

  const { collapseAllTrigger } = useCollapseContext();
  
  const { setAnchorId } = useContext(ContextAnchor);
  const [showSubCategories, setShowSubCategories] = useState(false);
  const toggleSubCategories = () => {
    setShowSubCategories(!showSubCategories);
  };

  const handleOpenAnchor = (anchor) => {
    // console.log("Main Point - öffne subs - handleOpenAnchor: ", anchor);
    setShowSubCategories(true);
    setAnchorId("");
  };

  useEffect(() => {
    if (collapseAllTrigger) {
      setShowSubCategories(false);
    }
  }, [collapseAllTrigger]);


  const handleAnchorClick = () => {
    setAnchorId(linkto);
    // console.log("SubPointNoChild handleAnchorClick: ", linkto);
    // console.log("navigiere jetzt");

    window.history.pushState({}, '', `#${id}`);
    navigate(`#${linkto}`);
    //hier sollte die aktuelle id gespeichert werden, in ein Array im localStorage (backId)
    //beim Pfeil Back sollte dann die letzte id aus dem Array genommen werden
    //und gespeichert werden in (nextId)
    //nur wenn backID.length > 0 soll ein backPfeil angezeigt werden
    //wenn ein anderer anchor geklickt wird, soll das nextId Array geleert werden
    //die Arrayverwaltung muss oben in overruls.js erfolgen
  };



//Kategorien:
// 0: Back
// A: Material
// B: Vorbereitung
// C: Ziel
// D: Ablauf
// E: Berechnung
// F: Infos
// Z: Suche

//Images werden hier gesetzt: components/categoryImage.js
const colorStyles = {
  A_material: { bg: "bg-red-200", text: "text-red-950" },
  B_setup: { bg: "bg-purple-200", text: "text-violet-950" },
  C_goal: { bg: "bg-green-200", text: "text-green-950" },
  D_gameplay: { bg: "bg-blue-200", text: "text-blue-950" },
  E_calculation: { bg: "bg-yellow-200", text: "text-yellow-950" },
  F_infos: { bg: "bg-cyan-200", text: "text-cyan-950" },
  
  default: { bg: "bg-white", text: "text-gray-700" }
};

const getColorStyle = (cat) => {
  return colorStyles[cat] || colorStyles.default;
};

const bgColor = cat !== undefined ? getColorStyle(cat).bg : "bg-white";
const fontColor = cat !== undefined ? getColorStyle(cat).text : "text-gray-700";


  const showExpand = children && children.length > 0 && !showSubCategories;
  const showReduce = children && children.length > 0 && showSubCategories;
  const showLinkPointer = children && children.length > 0 || linkto;
  const showVideo = children && children.length > 0 && children.some(child => child.type === "image");
  const showPic = children && children.length > 0 && children.some(child => child.type === "stillimage");
  const showMermaid = children && children.length > 0 && children.some(child => child.type === "mermaid");
  //const showVideo = children && children.length > 0 && children[0].type === "image";
  //const showMermaid = children && children.length > 0 && children[0].type === "mermaid";

  const showMiniVideo = children && children.length > 0 && children.some(child => child.children && child.children.some(grandChild => grandChild.type === "image"));
  const showMiniMermaid = children && children.length > 0 && children.some(child => child.children && child.children.some(grandChild => grandChild.type === "mermaid"));
  const showMiniPic = children && children.length > 0 && children.some(child => child.children && child.children.some(grandChild => grandChild.type === "stillimage"));


  return (
    <h3
      className={`or-sub-category ${fontColor}  text-lg md:text-xl rounded-lg shadow-lg p-1 md:p-4 pl-1 md:pl-2 pr-0 m-0 mr-0 border border-gray-500 ${bgColor} ${
        cat ? "mt-1 sm:mt-2 rounded-tl-[30px]" : "rounded-r-none mt-0.5"
      }`}
    >
      
      <div id={anchor || id}
        className={
          `${showLinkPointer ? "cursor-pointer" : ""}` +
          " items-center justify-left flex mb-1 md:mb-2"
        }
        // wenn linkto, dann handleAnchorClick, sonst toggleSubCategories (wenn children vorhanden)
        {...(!linkto && showLinkPointer && {
          onClick: toggleSubCategories,
          onKeyDown: toggleSubCategories,
          role: "presentation",
         })}
         {...(linkto && {
          onClick: handleAnchorClick,
          onKeyDown: handleAnchorClick,
          role: "presentation",
         })
         }
        
        
      >
        

          <Counter counter={counter} />
          <div className={`flex items-center space-x-0 w-full`}>
          {pre_svg && (
            <div className="-translate-x-2 sm:-translate-x-3"><PrePostSvg pre_svg={pre_svg} folder={folder} /></div>
            
          )}
          <div className={`${pre_svg ? "-translate-x-2" : "-translate-x-3 sm:-translate-x-4"}`}>
            <MarkdownText text={name} />
          </div>
          {linkto && (
        <div  onClick={handleAnchorClick} className="flex items-center cursor-pointer">
<IconExpandReduce link={true} />
        </div>
      )}
          {post_svg && (
            <PrePostSvg post_svg={post_svg} folder={folder} />
            
          )}
        </div>

        {/* Icons am rechten Rand */}
        <IconExpandReduce
          expand={showExpand}
          reduce={showReduce}
          vid={showVideo}
          pic={showPic}
          mermaid={showMermaid}
          miniVid={showMiniVideo}
          miniPic={showMiniPic}
          miniMermaid={showMiniMermaid}
        />
      </div>
      {children && (
        <div className={`or-main-category__sub-categories `}>
          
          {children.map((children, index) => (
            <SubPoint
              key={index}
              folder={folder}
              subCategory={children}
              showSelf={showSubCategories}
              onhandleOpenAnchor={handleOpenAnchor}
              language={language}
            />
          ))}
          
        </div>
      )}
      
    </h3>
  );
};

export default MainPoint;
